import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import SEO from "../components/seo"
import Layout from "../components/layout"
import PostsList from "../components/posts"

const Main = styled.main`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-height: 100vh;
  overflow-y: auto;
  padding: ${props => props.theme.wrapper.paddingY} ${props => props.theme.wrapper.paddingX};
`

const List = styled.ul`
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
`

const ListItem = styled.li`
`

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      allMarkdownRemark(
        filter: { frontmatter: { category: { eq: "blog" } } }
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              slug
              category
              title
              date
              tags
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO title="Home" />
      <PostsList />
      <Main>
        <h1>The Blog</h1>
        <hr/>
        <p>Welcome to The Blog. Here be musings. Pick one.</p>
        {data.allMarkdownRemark.edges.length &&
          <List>
            {data.allMarkdownRemark.edges.map(({ node }, index) => {
              const url = `${node.frontmatter.category ? `/${node.frontmatter.category}` : null}/${node.frontmatter.slug}`
              return (
                <ListItem key={index}>
                  <Link to={url}>{node.frontmatter.title}</Link>
                </ListItem>
              )
            })}
          </List>
        }
      </Main>
    </Layout>
  )
}

export default IndexPage
